import React, { lazy, Suspense, useState } from "react";
import { BrowserRouter, Routes, Route, Outlet, Navigate } from "react-router-dom";
import { UserProvider } from "./store/usercontext";
import "./assets/css/customer/scss/main.scss";
import "./App.css";
import DashboardService from "./controller/service/customers/DashboardService";
import { domainList } from "./assets/messages/domainList";
import Loader from "./components/customer/Loader";
import { errorCodeHandling } from "./utility/commonErrorCodeHandling";
import loggingErrorUtility from "./utility/loggingErrorUtility";
import { useModalContext } from "./store/errorModalContext";

const Sidemenu = lazy(() => import("./common/menu/sidemenu"));
const Signup = lazy(() => import("./view/tanents/signup"));
const Signin = lazy(() => import("./view/tanents/signin"));
const TokenExpired = lazy(() => import("./view/tanents/tokenExpired"));
const MobileVerify = lazy(() => import("./view/tanents/mobileVerify"));
const VerifySignup = lazy(() => import("./view/tanents/verifySignup"));
const Home = lazy(() => import("./view/customer/home"));
const Portfolio = lazy(() => import("./view/customer/portfolio"));
const AdvisorDetails = lazy(() => import("./view/customer/advisordetails"));
const PortfolioDetails = lazy(() => import("./view/customer/portfoliodetails"));
const Payment = lazy(() => import("./view/customer/payment"));
const Termsconditions = lazy(() => import("./view/customer/termsconditions"));
const Privacypolicy = lazy(() => import("./view/customer/privacypolicy"));
const Disclaimer = lazy(() => import("./view/customer/disclaimer"));
const RefundPolicy = lazy(() => import("./view/customer/refundpolicy"));
const Knowledge = lazy(() => import("./view/customer/knowledge"));
// const Services = lazy(() => import('./view/customer/services'));
const Faq = lazy(() => import("./view/customer/faq"));
const Joinus = lazy(() => import("./view/customer/joinus"));
const Distributor = lazy(() => import("./view/customer/distributor"));
const Publisher = lazy(() => import("./view/customer/publisher"));
const Dashboard = lazy(() => import("./view/customer/dashboard"));
const PageNotFound = lazy(() => import("./view/customer/404"));

function App() {
 const context = useModalContext();
 const [jwt, setJwt] = useState("");
 const value = { jwt, setJwt };

 // Check login session
 React.useEffect(() => {
  if (sessionStorage.getItem("jwt")) {
   setJwt(JSON.parse(sessionStorage.getItem("jwt")));
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);

 const dashboardService = new DashboardService();

 const PrivateWrapper = () => {
  return sessionStorage.getItem("jwt") === null ? (
   <Navigate to="/" />
  ) : Object.keys(JSON.parse(sessionStorage.getItem("jwt"))).length > 0 ? (
   <Outlet />
  ) : (
   <Navigate to="/" />
  );
 };

 return (
  <UserProvider value={value}>
   <div className="App">
    <Suspense fallback={<Loader />}>
     <BrowserRouter>
      <Routes>
       <Route path="signup" element={<Signup />} />
       <Route path="mobileVerify" element={<MobileVerify />} />
       <Route path="verifySignup" element={<VerifySignup />} />
       <Route path="/login" element={<Signin />} />
       <Route path="sidemenu" element={<Sidemenu />} />
       <Route path="tokenExpired" element={<TokenExpired />} />
       <Route path="home" element={<Home />} />
       <Route path="/" element={<Home />} />
       <Route path="portfolio" element={<Portfolio />} />
       <Route path="advisordetails/:tenentId" element={<AdvisorDetails />} />
       <Route path="/portfolio-details/:tenentId/:portfolioId" element={<PortfolioDetails />} />
       <Route path="payment" element={<Payment />} />
       <Route path="termsconditions" element={<Termsconditions />} />
       <Route path="privacypolicy" element={<Privacypolicy />} />
       <Route path="disclaimer" element={<Disclaimer />} />
       <Route path="refundpolicy" element={<RefundPolicy />} />
       <Route path="knowledge" element={<Knowledge />} />
       {/* <Route path="services" element={<Services />} /> */}
       <Route path="faq" element={<Faq />} />
       <Route path="joinus" element={<Joinus />} />
       <Route path="distributor" element={<Distributor />} />
       <Route path="publisher" element={<Publisher />} />
       <Route path="*" element={<PageNotFound />} />
       <Route element={<PrivateWrapper />}>
        <Route path="/dashboard" element={<Dashboard />} />
        {/* <Route path="sidemenu" element={<Sidemenu />} /> */}
       </Route>
      </Routes>
     </BrowserRouter>
    </Suspense>
   </div>
  </UserProvider>
 );
}

export default App;
